.carousel-item-content {
  min-height: 15rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-caption-custom a {
  color: white;
}

.carousel-caption {
  position: inherit;
  padding: 0;
}

@media (max-width: 768px) {
  .carousel {
    min-width: 20rem;
  }
}