body {
  margin: 0;
  font-family: 'Chakra Petch', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: 'Chakra Petch', sans-serif;
}

/* Video background style */
.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Overlay to darken the video for better text readability */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 1;
}

/* Centered content */
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
}

.content h1 {
  font-size: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.content h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.content h3 {
  font-size: 1.2rem;
  margin-top: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.links a {
  /* text-decoration: none; */
  color: white;
  padding: 0.5rem;
}

/* .fixed-links a {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.fixed-links a:hover {
  background: rgba(0, 0, 0, 0.9);
} */
